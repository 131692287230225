'use client'

import { MouseEvent, useContext, useEffect, useState } from 'react'
import Button from '@/components/tokens/Button'
import Audio from '@/contexts/AudioContext'
import { Release } from '@/lib/types'
import { ButtonColor, ButtonVariant } from '@/lib/types'

export default function ReleasePlayButton({ release }: { release: Release }) {
  const { playRelease, isPlaying, activeQueueItem, pauseResume } = useContext(
    Audio.Context,
  )

  const [releaseIsPlaying, setReleaseIsPlaying] = useState(false)
  useEffect(() => {
    if (
      isPlaying &&
      activeQueueItem()?.release?.publicKey === release.publicKey
    ) {
      setReleaseIsPlaying(true)
    } else {
      setReleaseIsPlaying(false)
    }
  }, [isPlaying, activeQueueItem()])

  const handlePlayPauseRelease = (e: MouseEvent) => {
    e.stopPropagation()

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    if (activeQueueItem()?.release?.publicKey === release.publicKey) {
      pauseResume()

      return
    }

    playRelease(release)
  }

  return (
    <div>
      {!releaseIsPlaying && (
        <Button
          icon="playSmall"
          variant={ButtonVariant.Square}
          color={ButtonColor.Solid}
          onClick={(e: MouseEvent<HTMLElement>) => handlePlayPauseRelease(e)}
        />
      )}
      {releaseIsPlaying && (
        <Button
          icon="pauseSmall"
          variant={ButtonVariant.Square}
          color={ButtonColor.Solid}
          onClick={(e: MouseEvent<HTMLElement>) => handlePlayPauseRelease(e)}
        />
      )}
    </div>
  )
}
