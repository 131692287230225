'use client'

import Link from 'next/link'
import { PropsWithChildren } from 'react'
import { EventWrapperProps } from '@/lib/types'
import { logEvent } from '@/lib/utils/event'

interface EventLinkProps extends EventWrapperProps {
  href: string
}

const EventLink = (props: PropsWithChildren<EventLinkProps>) => {
  const { action, category, wallet, params, children, href, className } = props

  return (
    <Link href={href} className={className}>
      <div onClick={() => logEvent(action, category, wallet, params)}>
        {children}
      </div>
    </Link>
  )
}

export default EventLink
