'use client'

import { PropsWithChildren } from 'react'
import { EventWrapperProps } from '@/lib/types'
import { logEvent } from '@/lib/utils/event'

const EventDiv = (props: PropsWithChildren<EventWrapperProps>) => {
  const { action, category, wallet, params, children, className } = props

  const handleLogEvent = (props: EventWrapperProps) => {
    const { action, category, wallet, params } = props

    if (action && category) {
      logEvent(action, category, wallet, params)
    }
  }

  return (
    <div
      onClick={() => handleLogEvent({ action, category, wallet, params })}
      className={className}
    >
      {children}
    </div>
  )
}

export default EventDiv
