import {
  NameRegistryState,
  Numberu32,
  Numberu64,
  createInstruction,
  createNameRegistry,
  deleteInstruction,
  getHashedName,
  getNameAccountKey,
  updateInstruction,
} from '@bonfida/spl-name-service'
import * as anchor from '@coral-xyz/anchor'
import Nina from '@nina-protocol/js-sdk'
import { WalletContextState } from '@solana/wallet-adapter-react'
import { Connection, PublicKey } from '@solana/web3.js'
import axios from 'axios'
import { deserializeUnchecked, serialize } from 'borsh'
import { encodeBase64 } from 'tweetnacl-util'
import { logEvent } from '@/lib/utils/event'
import initSdk from '@/lib/utils/initSdk'

const NINA_ID = new anchor.web3.PublicKey(
  'idHukURpSwMbvcRER9pN97tBSsH4pdLSUhnHYwHftd5',
)

const NINA_ID_SC_TLD = new anchor.web3.PublicKey(
  'MguVXe9Z18YDWxm3AZkSdiuRiEJ1UzvEyevFAxycsjw',
)

const NINA_ID_TW_TLD = new anchor.web3.PublicKey(
  '6nPJTCeFnp3QiLBDtPPkZqMkW3KccVgr1izLTF1Lq7VL',
)

const NINA_ID_IG_TLD = new anchor.web3.PublicKey(
  '7JVHPSJdVBNRgYdY3ibP33YksBzjpuBVasLj91Jj9jQA',
)

const NAME_PROGRAM_ID = new anchor.web3.PublicKey(
  'namesLPneVptA9Z5rqUDD9tMTWEJwofgaYwp8cawRkX',
)

const LAMPORTS_FOR_REVERSE_REGISTRY = 1433760
const LAMPORTS_FOR_NAME_ACCOUNT = 6657718
class ReverseSoundcloudRegistryState {
  soundcloudRegistryKey: Uint8Array
  soundcloudHandle: string
  static schema = new Map([
    [
      ReverseSoundcloudRegistryState,
      {
        kind: 'struct',
        fields: [
          ['soundcloudRegistryKey', [32]],
          ['soundcloudHandle', 'string'],
        ],
      },
    ],
  ])
  constructor(obj: {
    soundcloudRegistryKey: Uint8Array
    soundcloudHandle: string
  }) {
    this.soundcloudRegistryKey = obj.soundcloudRegistryKey
    this.soundcloudHandle = obj.soundcloudHandle
  }

  static retrieve = async (
    connection: Connection,
    reverseSoundcloudAccountKey: PublicKey,
  ) => {
    const reverseSoundcloudAddressAccount = await connection.getAccountInfo(
      reverseSoundcloudAccountKey,
      'processed',
    )

    if (!reverseSoundcloudAddressAccount) {
      throw new Error('Invalid reverse Soundcloud handle account provided')
    }

    const res = deserializeUnchecked(
      this.schema,
      ReverseSoundcloudRegistryState,
      reverseSoundcloudAddressAccount.data.slice(NameRegistryState.HEADER_LEN),
    )

    return res
  }

  static createLookupInstructions = async (
    soundcloudHandle: string,
    publicKey: PublicKey,
  ) => {
    const nameAccountKey = await getNameAccountKey(
      await getHashedName(soundcloudHandle),
      NINA_ID,
      NINA_ID_SC_TLD,
    )

    const hashedVerifiedPubkey = await getHashedName(publicKey.toString())

    const reverseRegistryKey = await getNameAccountKey(
      hashedVerifiedPubkey,
      NINA_ID,
      NINA_ID_SC_TLD,
    )

    const ReverseSoundcloudRegistryStateBuff = serialize(
      ReverseSoundcloudRegistryState.schema,
      new ReverseSoundcloudRegistryState({
        soundcloudRegistryKey: nameAccountKey.toBytes(),
        soundcloudHandle,
      }),
    )

    const createIx = createInstruction(
      NAME_PROGRAM_ID,
      anchor.web3.SystemProgram.programId,
      reverseRegistryKey,
      publicKey,
      NINA_ID,
      hashedVerifiedPubkey,
      new Numberu64(LAMPORTS_FOR_REVERSE_REGISTRY * 2),
      new Numberu32(ReverseSoundcloudRegistryStateBuff.length),
      NINA_ID,
      NINA_ID_SC_TLD,
      NINA_ID,
    )

    const reverseRegistryIx = updateInstruction(
      NAME_PROGRAM_ID,
      reverseRegistryKey,
      new Numberu32(0),
      Buffer.from(ReverseSoundcloudRegistryStateBuff),
      NINA_ID,
      // NINA_ID_SC_TLD, // included in v1
    )

    return [createIx, reverseRegistryIx]
  }
}

class ReverseInstagramRegistryState {
  instagramRegistryKey: Uint8Array
  instagramHandle: string

  static schema = new Map([
    [
      ReverseInstagramRegistryState,
      {
        kind: 'struct',
        fields: [
          ['instagramRegistryKey', [32]],
          ['instagramHandle', 'string'],
        ],
      },
    ],
  ])
  constructor(obj: {
    instagramRegistryKey: Uint8Array
    instagramHandle: string
  }) {
    this.instagramRegistryKey = obj.instagramRegistryKey
    this.instagramHandle = obj.instagramHandle
  }

  static retrieve = async (
    connection: Connection,
    reverseInstagramAccountKey: PublicKey,
  ) => {
    const reverseInstagramAddressAccount = await connection.getAccountInfo(
      reverseInstagramAccountKey,
      'processed',
    )

    if (!reverseInstagramAddressAccount) {
      throw new Error('Invalid reverse Instagram Handle account provided')
    }

    const res = deserializeUnchecked(
      this.schema,
      ReverseInstagramRegistryState,
      reverseInstagramAddressAccount.data.slice(NameRegistryState.HEADER_LEN),
    )

    return res
  }

  static createLookupInstructions = async (
    instagramHandle: string,
    publicKey: PublicKey,
  ) => {
    const nameAccountKey = await getNameAccountKey(
      await getHashedName(instagramHandle),
      NINA_ID,
      NINA_ID_IG_TLD,
    )

    const hashedVerifiedPubkey = await getHashedName(publicKey.toString())

    const reverseRegistryKey = await getNameAccountKey(
      hashedVerifiedPubkey,
      NINA_ID,
      NINA_ID_IG_TLD,
    )

    const ReverseInstagramRegistryStateBuff = serialize(
      ReverseInstagramRegistryState.schema,
      new ReverseInstagramRegistryState({
        instagramRegistryKey: nameAccountKey.toBytes(),
        instagramHandle,
      }),
    )

    const createIx = createInstruction(
      NAME_PROGRAM_ID,
      anchor.web3.SystemProgram.programId,
      reverseRegistryKey,
      publicKey,
      NINA_ID,
      hashedVerifiedPubkey,
      new Numberu64(LAMPORTS_FOR_REVERSE_REGISTRY * 2),
      new Numberu32(ReverseInstagramRegistryStateBuff.length),
      NINA_ID,
      NINA_ID_IG_TLD,
      NINA_ID,
    )

    const reverseRegistryIx = updateInstruction(
      NAME_PROGRAM_ID,
      reverseRegistryKey,
      new Numberu32(0),
      Buffer.from(ReverseInstagramRegistryStateBuff),
      NINA_ID,
      // NINA_ID_IG_TLD,
    )

    return [createIx, reverseRegistryIx]
  }
}

class ReverseTwitterRegistryState {
  twitterRegistryKey: Uint8Array
  twitterHandle: string
  static schema = new Map([
    [
      ReverseTwitterRegistryState,
      {
        kind: 'struct',
        fields: [
          ['twitterRegistryKey', [32]],
          ['twitterHandle', 'string'],
        ],
      },
    ],
  ])
  constructor(obj: { twitterRegistryKey: Uint8Array; twitterHandle: string }) {
    this.twitterRegistryKey = obj.twitterRegistryKey
    this.twitterHandle = obj.twitterHandle
  }

  static retrieve = async (
    connection: Connection,
    reverseTwitterAccountKey: PublicKey,
  ) => {
    const reverseTwitterAddressAccount = await connection.getAccountInfo(
      reverseTwitterAccountKey,
      'processed',
    )

    if (!reverseTwitterAddressAccount) {
      throw new Error('Invalid reverse Twitter Handle account provided')
    }

    const res = deserializeUnchecked(
      this.schema,
      ReverseTwitterRegistryState,
      reverseTwitterAddressAccount.data.slice(NameRegistryState.HEADER_LEN),
    )

    return res
  }

  static createLookupInstructions = async (
    twitterHandle: string,
    publicKey: PublicKey,
  ) => {
    const nameAccountKey = await getNameAccountKey(
      await getHashedName(twitterHandle),
      NINA_ID,
      NINA_ID_TW_TLD,
    )

    const hashedVerifiedPubkey = await getHashedName(publicKey.toString())

    const reverseRegistryKey = await getNameAccountKey(
      hashedVerifiedPubkey,
      NINA_ID,
      NINA_ID_TW_TLD,
    )

    const ReverseTwitterRegistryStateBuff = serialize(
      ReverseTwitterRegistryState.schema,
      new ReverseTwitterRegistryState({
        twitterRegistryKey: nameAccountKey.toBytes(),
        twitterHandle,
      }),
    )

    const createIx = createInstruction(
      NAME_PROGRAM_ID,
      anchor.web3.SystemProgram.programId,
      reverseRegistryKey,
      publicKey,
      NINA_ID,
      hashedVerifiedPubkey,
      new Numberu64(LAMPORTS_FOR_REVERSE_REGISTRY * 2),
      new Numberu32(ReverseTwitterRegistryStateBuff.length),
      NINA_ID,
      NINA_ID_TW_TLD,
      NINA_ID,
    )

    const reverseRegistryIx = updateInstruction(
      NAME_PROGRAM_ID,
      reverseRegistryKey,
      new Numberu32(0),
      Buffer.from(ReverseTwitterRegistryStateBuff),
      NINA_ID,
      // NINA_ID_TW_TLD,
    )

    return [createIx, reverseRegistryIx]
  }
}

const verifySoundcloud = async (
  soundcloudHandle: string,
  soundcloudToken: string,
  wallet: WalletContextState,
) => {
  await initSdk()
  const { provider } = Nina
  try {
    if (!wallet) {
      throw new Error('Wallet not connected')
    }

    const publicKey = wallet.publicKey as PublicKey
    logEvent('connection_sc_initiated', 'engagement', wallet, {
      soundcloudHandle,
    })

    // Create Name Account Registry
    const ix = await createNameRegistry(
      provider.connection,
      soundcloudHandle,
      96,
      NINA_ID,
      wallet.publicKey as anchor.web3.PublicKey,
      LAMPORTS_FOR_NAME_ACCOUNT,
      NINA_ID,
      NINA_ID_SC_TLD,
    )

    // Create Reverse Lookup Account Registry
    const [createIx, reverseRegistryIx] =
      await ReverseSoundcloudRegistryState.createLookupInstructions(
        soundcloudHandle,
        publicKey,
      )

    // Build and Sign Transaction
    const tx = new anchor.web3.Transaction({
      recentBlockhash: (await provider.connection.getLatestBlockhash())
        .blockhash,
      feePayer: NINA_ID,
    })

    tx.add(ix, createIx, reverseRegistryIx)

    const message = new TextEncoder().encode(publicKey.toBase58())
    const messageBase64 = encodeBase64(message)

    if (!wallet.signMessage) {
      throw new Error('Wallet does not support signMessage')
    }

    const signature = await wallet.signMessage(message)
    const signatureBase64 = encodeBase64(signature)

    const response = await axios.post(
      `${process.env.NINA_ID_ENDPOINT}/sc/register`,
      {
        handle: soundcloudHandle,
        token: soundcloudToken,
        tx: tx.serialize({ verifySignatures: false }).toString('base64'),
        publicKey: publicKey.toBase58(),
        message: messageBase64,
        signature: signatureBase64,
      },
    )

    logEvent('connection_sc_success', 'engagement', wallet, {
      soundcloudHandle,
    })

    if (response.status === 200) {
      return {
        success: true,
        message: `Successfully verified Soundcloud account ${soundcloudHandle}`,
      }
    }

    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } catch (error: any) {
    console.warn('error: ', error)

    logEvent('connection_sc_failure', 'engagement', wallet, {
      soundcloudHandle,
    })

    return {
      success: false,
      message:
        (error.response?.data.error as any) ||
        (`an error occurred: ${error}` as string),
    }
  }
}

const deleteSoundcloudVerification = async (
  soundcloudHandle: string,
  wallet: WalletContextState,
) => {
  await initSdk()
  const { provider } = Nina
  try {
    if (!wallet) {
      throw new Error('Wallet not connected')
    }

    const publicKey = wallet.publicKey as PublicKey
    logEvent('delete_sc_initiated', 'engagement', wallet, {
      soundcloudHandle,
    })

    const hashedSoundcloudHandle = await getHashedName(soundcloudHandle)

    const soundcloudRegistryKey = await getNameAccountKey(
      hashedSoundcloudHandle,
      NINA_ID,
      NINA_ID_SC_TLD,
    )

    const hashedVerifiedPubkey = await getHashedName(publicKey.toBase58())

    const reverseRegistryKey = await getNameAccountKey(
      hashedVerifiedPubkey,
      NINA_ID,
      NINA_ID_SC_TLD,
    )

    const instructions = [
      // Delete the user facing registry
      deleteInstruction(
        NAME_PROGRAM_ID,
        soundcloudRegistryKey,
        NINA_ID,
        publicKey,
      ),
      // Delete the reverse registry
      deleteInstruction(
        NAME_PROGRAM_ID,
        reverseRegistryKey,
        NINA_ID,
        publicKey,
      ),
    ]
    // Build and Sign Transaction

    let tx = new anchor.web3.Transaction({
      recentBlockhash: (await provider.connection.getLatestBlockhash())
        .blockhash,
      feePayer: NINA_ID,
    })
    tx.add(...instructions)

    if (!wallet.signTransaction) {
      throw new Error('Wallet does not support signTransaction')
    }

    const signedTx = await wallet?.signTransaction(tx)

    if (signedTx.signature) {
      /* eslint-disable-next-line  */
      // @ts-expect-error
      tx.addSignature(publicKey, signedTx.signature[0].signature as Buffer) // Fix: Access the correct index of the signedTx.signature array
    } else {
      tx = signedTx
    }

    const response = await axios.post(
      `${process.env.NINA_ID_ENDPOINT}/unregister`,
      {
        tx: tx.serialize({ verifySignatures: false }).toString('base64'),
      },
    )

    if (response.status === 200) {
      return {
        success: true,
        message: `Successfully disconnected ${soundcloudHandle}`,
      }
    }

    return {
      success: false,
      message: response.data.error,
    }
  } catch (error) {
    console.warn('error: ', error)
  }
}

const verifyTwitter = async (
  twitterHandle: string,
  twitterToken: string,
  wallet: WalletContextState,
) => {
  await initSdk()
  const { provider } = Nina
  try {
    if (!wallet) {
      throw new Error('Wallet not connected')
    }

    logEvent('connectiontwc_initiated', 'engagement', wallet, {
      twitterHandle,
    })

    // Create Name Account Registry
    const ix = await createNameRegistry(
      provider.connection,
      twitterHandle,
      96,
      NINA_ID,
      wallet.publicKey as anchor.web3.PublicKey,
      LAMPORTS_FOR_NAME_ACCOUNT,
      NINA_ID,
      NINA_ID_TW_TLD,
    )

    // Create Reverse Lookup Account Registry
    const [createIx, reverseRegistryIx] =
      await ReverseTwitterRegistryState.createLookupInstructions(
        twitterHandle,
        wallet.publicKey as PublicKey,
      )

    // Build and Sign Transaction
    const tx = new anchor.web3.Transaction({
      recentBlockhash: (await provider.connection.getLatestBlockhash())
        .blockhash,
      feePayer: NINA_ID,
    })

    tx.add(ix, createIx, reverseRegistryIx)

    const message = new TextEncoder().encode(wallet.publicKey?.toBase58())
    const messageBase64 = encodeBase64(message)

    if (!wallet.signMessage) {
      throw new Error('Wallet does not support signMessage')
    }

    const signature = await wallet.signMessage(message)
    const signatureBase64 = encodeBase64(signature)
    // Send Transaction To Server To Verify Signatures

    if (!wallet.publicKey) return

    const response = await axios.post(
      `${process.env.NINA_ID_ENDPOINT}/tw/register`,
      {
        handle: twitterHandle,
        token: twitterToken,
        tx: tx.serialize({ verifySignatures: false }).toString('base64'),
        publicKey: wallet.publicKey.toBase58(),
        message: messageBase64,
        signature: signatureBase64,
      },
    )

    if (response.status === 200) {
      return {
        success: true,
        message: `Successfully verified Twitter account ${twitterHandle}`,
      }
    }
  } catch (error: any) {
    return {
      success: false,
      message:
        (error.response?.data.error as any) ||
        (`an error occurred: ${error}` as string),
    }
  }
}

const deleteTwitterVerification = async (
  twitterHandle: string,
  wallet: WalletContextState,
) => {
  await initSdk()
  const { provider } = Nina
  try {
    if (!wallet.publicKey) return

    const publicKey = wallet.publicKey as PublicKey
    const hashedTwitterHandle = await getHashedName(twitterHandle)

    const twitterHandleRegistryKey = await getNameAccountKey(
      hashedTwitterHandle,
      NINA_ID,
      NINA_ID_TW_TLD,
    )

    const hashedVerifiedPubkey = await getHashedName(publicKey.toBase58())

    const reverseRegistryKey = await getNameAccountKey(
      hashedVerifiedPubkey,
      NINA_ID,
      NINA_ID_TW_TLD,
    )

    const instructions = [
      // Delete the user facing registry
      deleteInstruction(
        NAME_PROGRAM_ID,
        twitterHandleRegistryKey,
        NINA_ID,
        publicKey,
      ),
      // Delete the reverse registry
      deleteInstruction(
        NAME_PROGRAM_ID,
        reverseRegistryKey,
        NINA_ID,
        publicKey,
      ),
    ]

    // Build and Sign Transaction
    let tx = new anchor.web3.Transaction({
      recentBlockhash: (await provider.connection.getLatestBlockhash())
        .blockhash,
      feePayer: NINA_ID,
    })

    tx.add(...instructions)

    if (!wallet.signTransaction) return

    const signedTx = await wallet?.signTransaction(tx)

    if (signedTx.signature) {
      /* eslint-disable-next-line  */
      // @ts-expect-error
      tx.addSignature(publicKey, signedTx.signature[0].signature as Buffer) // Fix: Access the correct index of the signedTx.signature array
    } else {
      tx = signedTx
    }

    const response = await axios.post(
      `${process.env.NINA_ID_ENDPOINT}/unregister`,
      {
        tx: tx.serialize({ verifySignatures: false }).toString('base64'),
      },
    )

    if (response.status === 200) {
      return {
        success: true,
        message: `Successfully disconnected ${twitterHandle}`,
      }
    }

    return {
      success: false,
      message: response.data.error,
    }
  } catch (error) {
    console.warn('error: ', error)
  }
}

const verifyInstagram = async (
  instagramUserId: string,
  instagramHandle: string,
  instagramToken: string,
  wallet: WalletContextState,
) => {
  try {
    if (!wallet || !wallet.publicKey) return

    const { provider } = Nina

    // Create Name Account Registry
    const ix = await createNameRegistry(
      provider.connection,
      instagramHandle,
      96,
      NINA_ID,
      wallet.publicKey,
      LAMPORTS_FOR_NAME_ACCOUNT,
      NINA_ID,
      NINA_ID_IG_TLD,
    )

    // Create Reverse Lookup Account Registry
    const [createIx, reverseRegistryIx] =
      await ReverseInstagramRegistryState.createLookupInstructions(
        instagramHandle,
        wallet.publicKey,
      )

    // Build and Sign Transaction
    const tx = new anchor.web3.Transaction({
      recentBlockhash: (await provider.connection.getLatestBlockhash())
        .blockhash,
      feePayer: NINA_ID,
    })

    tx.add(ix, createIx, reverseRegistryIx)

    if (!wallet.signTransaction || !wallet.signMessage) return

    await wallet.signTransaction(tx)

    const message = new TextEncoder().encode(wallet.publicKey.toBase58())
    const messageBase64 = encodeBase64(message)
    const signature = await wallet.signMessage(message)
    const signatureBase64 = encodeBase64(signature)

    // Send Transaction To Server To Verify Signatures
    const response = await axios.post(
      `${process.env.NINA_ID_ENDPOINT}/ig/register`,
      {
        handle: instagramHandle,
        userId: instagramUserId,
        tx: tx.serialize({ verifySignatures: false }).toString('base64'),
        publicKey: wallet.publicKey.toBase58(),
        message: messageBase64,
        signature: signatureBase64,
        token: instagramToken,
      },
    )

    if (response.status === 200) {
      return {
        success: true,
        message: `Successfully verified Twitter account ${instagramHandle}`,
      }
    }
  } catch (error: any) {
    console.warn('error: ', error)

    return {
      success: false,
      message:
        (error.response?.data.error as any) ||
        (`an error occurred: ${error}` as string),
    }
  }
}

const deleteInstgramVerification = async (
  instagramHandle: string,
  wallet: WalletContextState,
) => {
  await initSdk()
  const { provider } = Nina
  try {
    if (!wallet.publicKey) return

    const publicKey = wallet.publicKey as PublicKey
    const hashedInstagramHandle = await getHashedName(instagramHandle)

    const instagramHandleRegistryKey = await getNameAccountKey(
      hashedInstagramHandle,
      NINA_ID,
      NINA_ID_IG_TLD,
    )

    const hashedVerifiedPubkey = await getHashedName(publicKey.toBase58())

    const reverseRegistryKey = await getNameAccountKey(
      hashedVerifiedPubkey,
      NINA_ID,
      NINA_ID_IG_TLD,
    )

    const instructions = [
      // Delete the user facing registry
      deleteInstruction(
        NAME_PROGRAM_ID,
        instagramHandleRegistryKey,
        NINA_ID,
        publicKey,
      ),
      // Delete the reverse registry
      deleteInstruction(
        NAME_PROGRAM_ID,
        reverseRegistryKey,
        NINA_ID,
        publicKey,
      ),
    ]

    // Build and Sign Transaction
    let tx = new anchor.web3.Transaction({
      recentBlockhash: (await provider.connection.getLatestBlockhash())
        .blockhash,
      feePayer: NINA_ID,
    })

    tx.add(...instructions)

    if (!wallet.signTransaction) return

    const signedTx = await wallet?.signTransaction(tx)

    if (signedTx.signature) {
      /* eslint-disable-next-line  */
      // @ts-expect-error
      tx.addSignature(publicKey, signedTx.signature[0].signature as Buffer) // Fix: Access the correct index of the signedTx.signature array
    } else {
      tx = signedTx
    }

    const response = await axios.post(
      `${process.env.NINA_ID_ENDPOINT}/unregister`,
      {
        tx: tx.serialize({ verifySignatures: false }).toString('base64'),
      },
    )

    if (response.status === 200) {
      return {
        success: true,
        message: `Successfully disconnected ${instagramHandle}`,
      }
    }

    return {
      success: false,
      message: response.data.error,
    }
  } catch (error) {
    console.warn('error: ', error)
  }
}

export {
  verifySoundcloud,
  deleteSoundcloudVerification,
  verifyTwitter,
  deleteTwitterVerification,
  verifyInstagram,
  deleteInstgramVerification,
}
