'use client'

import clsx from 'clsx'
import { useRouter } from 'next/navigation'
import { useContext, useEffect, useState } from 'react'
import Button from '@/components/tokens/Button'
import Toast from '@/contexts/ToastContext'
import Wallet from '@/contexts/WalletContext'
import { fetchAccountVerifications } from '@/lib/account/fetchAccountVerifications'
import {
  AccountVerification,
  ButtonColor,
  ButtonFont,
  ButtonResponsiveness,
  ButtonVariant,
  VerificationSource,
  VerificationTypes,
} from '@/lib/types'
import { logEvent } from '@/lib/utils/event'
import {
  deleteInstgramVerification,
  deleteSoundcloudVerification,
  deleteTwitterVerification,
  verifyInstagram,
  verifySoundcloud,
  verifyTwitter,
} from '@/lib/verifications/verify'

type AccountVerificationSettingsProps = {
  verificationFlowActive?: boolean
  setVerificationFlowActive?: React.Dispatch<React.SetStateAction<boolean>>
  verificationAttempted?: boolean | undefined
  setVerificationAttempted?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined
  soundcloudHandle?: string
  soundcloudToken?: string
  twitterHandle?: string
  twitterToken?: string
  instagramHandle?: string
  instagramToken?: string
  instagramUserId?: string
}

type VerificationTypesWithValues = {
  type: VerificationSource
  link: string | null
  verification: AccountVerification | undefined
}

export default function AccountVerificationSettings(
  props: AccountVerificationSettingsProps,
) {
  const {
    verificationFlowActive,
    setVerificationFlowActive,
    soundcloudHandle,
    soundcloudToken,
    twitterHandle,
    twitterToken,
    instagramHandle,
    instagramToken,
    instagramUserId,
    setVerificationAttempted,
    verificationAttempted,
  } = props

  const [verifications, setVerifications] = useState<
    AccountVerification[] | undefined
  >([])

  const [typesWithValues, setTypesWithValues] = useState<
    VerificationTypesWithValues[]
  >([])

  const [pending, setPending] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const { wallet } = useContext(Wallet.Context)
  const { addToast } = useContext(Toast.Context)
  const router = useRouter()

  const [disconnectingSuccessMessage, setDisconnectingSuccessMessage] =
    useState<string | null>(null)

  const verificationTypes: VerificationTypes[] = [
    {
      type: 'twitter',
      link: null,
    },
    {
      type: 'soundcloud',
      link: null,
    },
    {
      type: 'instagram',
      link: null,
    },
  ]

  useEffect(() => {
    //initial fetch
    const fetchVerifications = async () => {
      if (!wallet.publicKey) return

      const response = await fetchAccountVerifications(
        wallet.publicKey?.toBase58(),
      )

      setVerifications(response.verifications)
    }

    if (verificationFlowActive && !verificationAttempted) {
      setPending(true)
    }

    fetchVerifications()
  }, [])

  useEffect(() => {
    //update  dom after connect/disconnect
    const data = verificationTypes.map(
      (verificationType: VerificationTypes) => {
        const verification = verifications?.find(
          (verification: AccountVerification) =>
            verification.type === verificationType.type,
        )

        return {
          ...verificationType,
          verification,
        }
      },
    )

    setTypesWithValues(data)
  }, [verifications])

  useEffect(() => {
    const cleanUpAndUpdate = async () => {
      setVerificationFlowActive && setVerificationFlowActive(false)
      setVerificationAttempted && setVerificationAttempted(true)
      localStorage.removeItem('verificationCodeSource')

      if (!wallet.publicKey) return

      const response = await fetchAccountVerifications(
        wallet?.publicKey.toBase58(),
      )

      setVerifications(response.verifications)
      setPending(false)
    }

    const handleResponse = async (
      verificationResult:
        | {
            success: boolean
            message: string
          }
        | undefined,
      source: string,
    ) => {
      if (verificationResult) {
        logEvent('verification_connected', 'engagement', wallet, {
          type: source,
        })
        const { success, message } = verificationResult

        if (success) {
          addToast({
            message: message,
            variant: 'success',
          })

          await cleanUpAndUpdate()

          return
        } else {
          setError(message)
          addToast({
            message: message,
            variant: 'error',
          })
          await cleanUpAndUpdate()

          return
        }
      }

      await cleanUpAndUpdate()
      setError('Something went wrong')
    }

    const handleVerification = async () => {
      setPending(true)

      const verificationCodeSource = localStorage.getItem(
        'verificationCodeSource',
      )

      switch (verificationCodeSource) {
        case 'soundcloud':
          try {
            if (soundcloudHandle && soundcloudToken) {
              const scResult = await verifySoundcloud(
                soundcloudHandle,
                soundcloudToken,
                wallet,
              )

              await handleResponse(scResult, verificationCodeSource)
            }
          } catch (error) {
            console.warn('error in SC verification>> ', error)
            setPending(false)
            await handleResponse(undefined, verificationCodeSource)

            return
          }

          break
        case 'twitter':
          try {
            console.warn('twitterHandle>> ', twitterHandle)
            console.warn('twitterToken>> ', twitterToken)

            if (twitterHandle && twitterToken) {
              const twitterResult = await verifyTwitter(
                twitterHandle,
                twitterToken,
                wallet,
              )

              console.warn('twitterResult>> ', twitterResult)
              await handleResponse(twitterResult, verificationCodeSource)
            }
          } catch (error) {
            console.warn('error in twitter verification>> ', error)
            setPending(false)
            await handleResponse(undefined, verificationCodeSource)
          }
          break
        case 'instagram':
          try {
            if (instagramHandle && instagramToken && instagramUserId) {
              const instagramResult = await verifyInstagram(
                instagramUserId,
                instagramHandle,
                instagramToken,
                wallet,
              )

              await handleResponse(instagramResult, verificationCodeSource)
            }
          } catch (error) {
            console.warn('error in instagram verification>> ', error)
            setPending(false)
            await handleResponse(undefined, verificationCodeSource)
          }
          break
      }
    }

    if (wallet.publicKey && verificationFlowActive && !verificationAttempted) {
      handleVerification()
    }
  }, [
    wallet.publicKey,
    verificationFlowActive,
    soundcloudHandle,
    soundcloudToken,
    twitterHandle,
    twitterToken,
    instagramHandle,
    instagramToken,
    instagramUserId,
  ])

  const handleConnectAccount = (type: VerificationSource) => {
    localStorage.setItem('verificationCodeSource', type)
    switch (type) {
      case 'soundcloud':
        router.push(
          `https://soundcloud.com/connect?client_id=${process.env.SC_CLIENT_ID}&redirect_uri=${process.env.IDENTITY_REDIRECT_URI}&response_type=code&scope=non-expiring`,
        )
        break

      case 'twitter':
        router.push(
          `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${process.env.TWITTER_AUTH_CLIENT_ID}&redirect_uri=${process.env.IDENTITY_REDIRECT_URI}&scope=users.read%20tweet.read&state=state&code_challenge=challenge&code_challenge_method=plain`,
        )
        break

      case 'instagram':
        router.push(
          `https://api.instagram.com/oauth/authorize?client_id=${process.env.IG_CLIENT_ID}&redirect_uri=${process.env.IDENTITY_REDIRECT_URI}&scope=user_profile,user_media&response_type=code`,
        )
        break
    }
  }

  const disconnectAccount = async (type: VerificationSource) => {
    let verificationDeleteResult:
      | { success: boolean; message: string }
      | undefined

    // const usingMagicWallet = wallet.wallet?.adapter.name === 'Nina'
    switch (type) {
      case 'soundcloud':
        const soundcloudHandle = verifications?.find(
          (verification) => verification.type === 'soundcloud',
        )?.value

        if (soundcloudHandle) {
          verificationDeleteResult = await deleteSoundcloudVerification(
            soundcloudHandle,
            wallet,
          )
          await cleanUpDisconnect(type)

          return verificationDeleteResult
        }

        break

      case 'instagram':
        const instagramHandle = verifications?.find(
          (verification) => verification.type === 'instagram',
        )?.value

        if (instagramHandle) {
          verificationDeleteResult = await deleteInstgramVerification(
            instagramHandle,
            wallet,
          )

          await cleanUpDisconnect(type)

          return verificationDeleteResult
        }

        break

      case 'twitter':
        const twitterHandle = verifications?.find(
          (verification) => verification.type === 'twitter',
        )?.value

        if (twitterHandle) {
          verificationDeleteResult = await deleteTwitterVerification(
            twitterHandle,
            wallet,
          )

          await cleanUpDisconnect(type)

          return verificationDeleteResult
        }

        break
    }
  }

  const cleanUpDisconnect = async (type: VerificationSource) => {
    if (!wallet.publicKey) return

    logEvent('verification_disconnected', 'engagement', wallet, {
      type,
    })

    const response = await fetchAccountVerifications(
      wallet?.publicKey.toBase58(),
    )

    setVerifications(response.verifications)
  }

  const handleDisconnectAccount = async (type: VerificationSource) => {
    setPending(true)
    localStorage.setItem('verificationCodeSource', type)
    const verificationDeleteResult = await disconnectAccount(type)
    setPending(false)

    if (verificationDeleteResult) {
      const { success, message } = verificationDeleteResult

      if (success) {
        setDisconnectingSuccessMessage(message)
        addToast({
          message: message,
          variant: 'success',
        })
      } else {
        addToast({
          message: message,
          variant: 'error',
        })
      }
    }
  }

  return (
    <div className="w-full">
      <ul>
        {typesWithValues.map((verificationType, index) => {
          const verification = verificationType.verification

          const label = verification
            ? `${verificationType.type}: ${verification.value} `
            : `${verificationType.type} `

          const isActiveType =
            localStorage.getItem('verificationCodeSource') ===
            verificationType.type

          return (
            <li className="mt-8" key={index}>
              {' '}
              <Button
                color={ButtonColor.Grey}
                label={label}
                variant={ButtonVariant.Large}
                font={ButtonFont.Body1}
                textColor={ButtonColor.Grey}
                responsiveness={ButtonResponsiveness.Full}
                isSubmitting={
                  pending &&
                  localStorage.getItem('verificationCodeSource') ===
                    verificationType.type
                }
                adornment={verification ? 'closeSmall' : 'plus'}
                adornmentPosition="left"
                adornmentSize={!verification ? 15 : undefined}
                submittingLabel={
                  verificationFlowActive
                    ? `Connecting ${verificationType.type}`
                    : `Disconnecting ${verificationType.type}`
                }
                onClick={async () => {
                  verification
                    ? await handleDisconnectAccount(verificationType.type)
                    : await handleConnectAccount(verificationType.type)
                }}
                styleOverride={clsx(
                  'capitalize flex',
                  pending && 'hidden', //hide other buttons while one is pending
                  pending && isActiveType && '!flex', //show active button
                )}
              />
            </li>
          )
        })}
        {error && <p className="text-red-500 ui-1 mt-8 text-red-55">{error}</p>}
        {disconnectingSuccessMessage && (
          <>
            <p className="ui-1 mt-8 text-green-dark ">
              {disconnectingSuccessMessage}.
            </p>
            <p className="ui-1 mt-8 text-green-dark ">
              This may take a couple minutes to update.
            </p>
          </>
        )}
      </ul>
    </div>
  )
}
